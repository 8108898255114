export default [
  {
    name: 'Measurement',
    description: 'Something that goes up and down over time.',
    examples: ['Weight', 'Account balance'],
    icon: 'Line chart',
    id: 'measurement'
  },
  {
    name: 'Count',
    description: '',
    examples: ['Calories eaten', 'Pull ups'],
    icon: 'Bar chart',
    id: 'count'
  },
  {
    name: 'Binary',
    description: 'Either you accomplished it or not.',
    examples: ['Worked out', 'Studied'],
    icon: 'Checked circle',
    id: 'binary'
  },
  {
    name: 'Categorical',
    description: '',
    examples: ['Mood', 'Activity level'],
    icon: 'Pie chart',
    id: 'categorical'
  }
]
