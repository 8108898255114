import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from './AppContext'
import Entry from './Entry'
import CheckboxEntry from './CheckboxEntry'
import { getAllMetrics } from '../utils/db'
import noMetricsPlaceholder from '../images/no-metrics-placeholder.png'

const toDateId = (date) => {
  return date.format('YYYY-MM-DD')
}

const Entries = () => {
  const [appState] = useContext(AppContext)
  const [metrics, setMetrics] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllMetrics()
        setMetrics(data)
      } catch (error) {
        console.log('error getting all metrics', error)
      }
    }

    fetchData()

    console.log('<Entries/> rendered for ' + toDateId(appState.date))
  }, [appState.date])

  const renderEntry = (metric) => {
    switch (metric.stat) {
      case 'measurement': return (
        <Entry metric={metric} />
      )
      case 'count': return (
        <Entry metric={metric} />
      )
      case 'binary': return (
        <CheckboxEntry metric={metric} />
      )
      case 'categorical': return (
        null
        // <CheckboxEntry metric={metric} />
      )
      default:
        return null
    }
  }

  return (
    <>
      {metrics.length === 0 ? (
        <>
          <img alt='Person pointing at a graph' className='placeholder-image' src={noMetricsPlaceholder} />
          <p className='placeholder-message'>Start tracking your personal stats<br />by adding a new metric.</p>
        </>
      ) : (
        metrics.map(metric => {
          console.log(metric)
          const entry = renderEntry(metric)
          console.log('ENTRY IS', entry)
          return entry
        })
      )}
      {/* {appState.metrics.map((metric) => {
        console.log(metric)
        const entry = renderEntry(metric)
        console.log('ENTRY IS', entry)
        return entry
      })} */}
    </>
  )
}

export default Entries
