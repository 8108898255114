import React, { useEffect, useState } from 'react'
import { ReactComponent as TrashIcon } from '../images/trash-filled.svg'
import { deleteAllData } from '../utils/db'

const SettingsPage = () => {
  const [someState] = useState()

  console.log(someState)

  useEffect(() => {
    console.log('<SettingsPage/> rendered')
  }, [])

  const handleDeleteButton = async () => {
    try {
      deleteAllData()
      console.log('deleted')
    } catch (error) {
      console.log(error, 'could not delete')
    }
  }

  return (
    <div className='container'>
      <h1>Settings</h1>
      <h2>Delete all your data</h2>
      <button className='alert-button' onClick={handleDeleteButton}>
        <TrashIcon className='icon' /> Delete your data
      </button>
    </div>
  )
}

export default SettingsPage
