const isValidValue = (value) => {
  const tests = [
    /[0-9.]+/.test(value), // must be "0-9" or "."
    !/\.{2,}/.test(value), // does not have multiple "."
    !/^0\d/.test(value), // is not 0 followed by a number
    !isNaN(value), // is not nan
    value.split('.')?.[1] // is less than 4 decimal places
      ? value.split('.')[1].length < 4
      : true
  ]

  if (tests.some((test, i) => test === false)) {
    return false
  } else {
    return true
  }
}

export default isValidValue
