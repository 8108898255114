const maxDecimalPlaces = (values) => {
  return values.reduce((acc, cur) => {
    const string = cur.toString()
    const decimals = string.split('.')[1]
    const decimalsLength = decimals ? decimals.length : 0

    if (decimalsLength > acc) {
      return decimalsLength
    } else {
      return acc
    }
  }, 0)
}

export default maxDecimalPlaces
