import React, { useEffect, useState } from 'react'
import { createNewMetric } from '../utils/db'
import lineChart from '../images/line-chart.png'
import barChart from '../images/bar-chart.png'
import pieChart from '../images/pie-chart.png'
import checkCircle from '../images/check-circle.png'
import metricTypes from '../utils/metricTypes'

const NewMetricPage = () => {
  const [name, setName] = useState('')
  const [type, setType] = useState()
  const iconTitleToImagePathMap = {
    'Line chart': lineChart,
    'Bar chart': barChart,
    'Checked circle': checkCircle,
    'Pie chart': pieChart
  }

  useEffect(() => {
    console.log('<NewMetricPage/> rendered')
  }, [])

  const handleTypeClick = (type) => {
    setType(type)
  }

  const handleNameInput = (event) => {
    let value = event.target.value
    value = value
      .replaceAll(/[^a-zA-Z ]/g, '')
      .replace(/^\w/, (c) => c.toUpperCase())

    setName(value)
  }

  const handleCreateNewMetricButton = async () => {
    // create new metric in db
    try {
      await createNewMetric(name, type)
      console.log('Created new metric', name, type)
    } catch (error) {
      console.log('failed to create new metric', error, name, type)
    }
  }

  console.log(type, name)

  return (
    <>
      <div className='container'>
        <h1>Add a metric</h1>
      </div>
      <div className='container list'>
        <h2>Name</h2>
        <input className='text-field capitalize' onInput={handleNameInput} value={name} />
        <h2>Type</h2>
        {metricTypes.map((metricType, i) => (
          <div
            key={i}
            className={`card bg-hover flex pointer ${type === metricType.id && 'selected'}`}
            onClick={() => handleTypeClick(metricType.id)}
          >
            <div className='square'>
              <img alt={metricType.icon} className='square-image' src={iconTitleToImagePathMap[metricType.icon]} />
            </div>
            <div>
              <div className='label'>{metricType.name}</div>
              Ex. {metricType.examples.join(', ')}
            </div>
          </div>
        ))}
        {name && type && (
          <button className='button' onClick={handleCreateNewMetricButton}>Create new metric</button>
        )}
        {name} {type}
      </div>
    </>
  )
}

export default NewMetricPage
