import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import LineGraph from './LineGraph'
// import BarGraph from './BarGraph'
import { getAllEntriesForMetric, getMetric } from '../utils/db'
import day from '../utils/day'
// import { AppContext } from './AppContext'
import { ReactComponent as ChevronRightIcon } from '../images/chevron-right.svg'
import maxDecimalPlaces from '../utils/maxDecimalPlaces'
// import metricTypes from '../utils/metricTypes'

const StatsPage = () => {
  const { metricId } = useParams()
  const [entries, setEntries] = useState([])
  const [metric, setMetric] = useState({})
  // const [stats, setStats] = useState({})

  console.log(metricId)

  useEffect(() => {
    const fetchData = async () => {
      setMetric(await getMetric(metricId))
      setEntries(await getAllEntriesForMetric(metricId))
    }

    fetchData()

    // console.log(`<StatsPage/> rendered for ${metric.name}`)
  }, [metricId])

  const minValue = entries => {
    if (entries.length === 0) return '---'
    const values = entries.map(entry => entry.value)
    return Math.min(...values)
  }

  const avgValue = entries => {
    if (entries.length === 0) return '---'
    const values = entries.map(entry => entry.value)
    const avg = values.reduce((a, b) => (a + b)) / values.length

    return Number((avg).toFixed(maxDecimalPlaces(values)))
  }

  const maxValue = entries => {
    if (entries.length === 0) return '---'
    const values = entries.map(entry => entry.value)
    return Math.max(...values)
  }

  return (
    <>
      <div className='container'>
        <h1>{metric.name}</h1>
      </div>
      <div className='card'>
        {metric.stat === 'measurement' && (
          <LineGraph data={entries} />
        )}
        {metric.stat === 'count' && (
          <LineGraph data={entries} />
        )}
      </div>
      <div className='card stats '>
        <table>
          <thead>
            <tr>
              <th>Min</th>
              <th>Average</th>
              <th>Max</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{minValue(entries)}</td>
              <td>{avgValue(entries)}</td>
              <td>{maxValue(entries)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='card'>
        <h2>Entries</h2>
        {entries.map(entry => (
          // <div className='entry-list-item' key={entry.id}>
          <Link key={entry.id} className='entry-list-item' to={day.fromIdToUrlPath(entry.date)}>
            <span>{day.toHumanString(entry.date)}</span>
            <span>{entry.value}</span>
            <span><ChevronRightIcon className='icon' /></span>
          </Link>
          // </div>
        ))}
      </div>
    </>
  )
}

export default StatsPage
