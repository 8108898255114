import React from 'react'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { ReactComponent as ArrowLeftIcon } from '../images/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../images/arrow-right.svg'

const DateBar = () => {
  const { y, m, d } = useParams()

  const day = dayjs(`${y}-${m}-${d}`, 'YYYY-MM-DD')
  const yesterdayPath = day.subtract(1, 'day').format('/YYYY/MM/DD')
  const tomorrowPath = day.add(1, 'day').format('/YYYY/MM/DD')

  return (
    <div className='date-bar'>
      <Link to={yesterdayPath}>
        <button className='date-bar-button'>
          <span role='img' aria-label='prev'>
            <ArrowLeftIcon className='icon' />
          </span>
        </button>
      </Link>
      <span className='date-bar-date-container'>
        {/* <div className='date-bar-day-of-week'>{day.format('dddd')}</div> */}
        <div className='date-bar-month-day-year'>
          {day.format('dddd, MMMM Do, YYYY')}
        </div>
      </span>
      <Link to={tomorrowPath}>
        <button className='date-bar-button'>
          <span role='img' aria-label='next'>
            <ArrowRightIcon className='icon' />
          </span>
        </button>
      </Link>
    </div>
  )
}

export default DateBar
