import Dexie from 'dexie'
import { toDashCase } from './dashCase'

const db = new Dexie('daily')

db.version(1).stores({
  entries: 'id, date, metric, value',
  metrics: 'id, name, stat'
})

const createNewMetric = (name, stat) => {
  // todo: add validation, if exists, etc
  return db.metrics.add({
    id: toDashCase(name),
    name,
    stat
  })
}

const deleteAllData = () => {
  return db.delete()
}

const getAllEntries = async () => {
  const entries = await db.entries.toArray()
  console.log('All Entries', entries)
}

const getAllEntriesForMetric = async (metric) => {
  try {
    const entries = await db.entries
      .where({ metric: metric })
      .reverse()
      .sortBy('date')

    console.log('All Entries for ' + metric, entries)

    return entries
  } catch (error) {
    console.log('Error getting entries for ' + metric)

    return []
  }
}

const getAllMetrics = async () => {
  return db.metrics.toArray()
}

const getMetric = async (id) => {
  try {
    const metric = await db.metrics
      .where({ id: id })
      .first()

    console.log('Metric for ' + id, metric)

    return metric
  } catch (error) {
    console.log('Error getting metric for ' + id)

    return null
  }
}

getAllEntries()

export {
  createNewMetric,
  deleteAllData,
  getAllMetrics,
  getMetric,
  getAllEntriesForMetric
}

export default db
