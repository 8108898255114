import React, { useState } from 'react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

const AppContext = React.createContext([{}, () => {}])

const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    date: dayjs(),
    metrics: [
      { id: 'weight', inputType: 'numeric', label: 'Weight', units: 'lbs' },
      { id: 'worked-out', inputType: 'checkbox', label: 'Worked out' },
      {
        id: 'cups-of-water',
        inputType: 'counter',
        label: 'Cups of water',
        units: 'cups'
      },
      {
        id: 'alcoholic drinks',
        inputType: 'counter',
        label: 'Alcoholic drinks',
        units: 'drinks'
      }
    ]
  })

  return (
    <AppContext.Provider value={[appState, setAppState]}>
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
