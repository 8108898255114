import React, { useEffect, useState } from 'react'
import db from '../utils/db'
import { Link, useParams } from 'react-router-dom'
import isValidValue from '../utils/isValidValue'
import { ReactComponent as GraphIcon } from '../images/graph-filled.svg'
import { ReactComponent as TrashIcon } from '../images/trash-filled.svg'

const Entry = ({ metric }) => {
  const [value, setValue] = useState('')
  const { y, m, d } = useParams()

  const date = `${y}-${m}-${d}`

  const handleChange = async (event) => {
    const newValue = event.target.value

    if (isValidValue(newValue)) {
      setValue(newValue)
    } else {
      return
    }

    const entryValue = Number(newValue)

    await db.entries.put({
      id: date + '-' + metric.id,
      date: date,
      metric: metric.id,
      value: entryValue
    })

    console.log(`Put value ${entryValue} for ${metric.name} for ${date}`)
  }

  const handleFocus = (event) => {
    if (value === '---') {
      setValue('')
    }
  }

  const handleClearButton = async (event) => {
    setValue('')

    await db.entries
      .where({
        date: date,
        metric: metric.id
      })
      .delete()
  }

  useEffect(() => {
    const fetchData = async () => {
      const entry = await db.entries
        .where({
          metric: metric.id,
          date: date
        })
        .first()

      if (entry !== undefined) {
        setValue(entry.value)
      } else {
        setValue('---')
      }
    }

    fetchData()

    console.log(`<Entry/> ${metric.name} rendered for ${date}`)
  }, [date, metric.id, metric.name])

  return (
    <div className='entry'>
      <p className='input-label'>{metric.name}</p>
      <div className={`entry-input numeric ${metric.stat}`}>
        <input value={value} onChange={handleChange} onFocus={handleFocus} type='number' />
      </div>
      {/* {showGraph ? (
        <LineGraph entryType={entryTypeId} />
      ) : (
        <button onClick={handleShowGraphClick}>Show graph</button>
      )} */}
      <div className='button-group'>
        <Link to={`/stats/${metric.id}`}>
          <button>
            <GraphIcon className='icon' /> Graph
          </button>
        </Link>
        <button onClick={handleClearButton}>
          <TrashIcon className='icon' /> Clear
        </button>
      </div>
    </div>
  )
}

export default Entry
