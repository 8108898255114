import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import day from '../utils/day'

const LineGraph = ({ data }) => {
  if (data.length === 0) return null // TODO: Still take up space

  const graphData = [
    {
      id: 'line',
      data: data.map((entry) => ({ x: entry.date, y: entry.value }))
    }
  ]

  const rem = 16

  return (
    <div className='graph'>
      <ResponsiveLine
        data={graphData}
        margin={{ top: 1 * rem, right: 2.5 * rem, bottom: 2.5 * rem, left: 1.5 * rem }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'day',
          useUTC: false
        }}
        xFormat='time:%Y-%m-%d'
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto'
        }}
        axisTop={null}
        axisLeft={null}
        axisBottom={{
          format: '%b %e',
          tickSize: 0,
          tickPadding: rem,
          tickValues: 5
        }}
        axisRight={{
          tickSize: 0,
          tickPadding: rem,
          tickValues: 5
        }}
        colors={['#2febd2']}
        curve='linear'
        // curve={'catmullRom'}
        // enableCrosshair={false}
        enableGridX={false}
        enableGridY={false}
        // isInteractive={false}
        lineWidth={2}
        pointSize={8}
        pointBorderWidth={8}
        useMesh
        tooltip={({ point }) => {
          console.log(point)
          const date = day.toFormat(point.data.xFormatted, 'MMM D, YYYY')
          const value = point.data.yFormatted
          return (
            <div className='tool-tip'>
              <div className='tool-tip-date'>{date}</div>
              <div className='tool-tip-value'>{value}</div>
            </div>
          )
        }}
      />
    </div>
  )
}

export default LineGraph
