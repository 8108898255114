import React, { useEffect } from 'react'
import { AppProvider } from './components/AppContext'
import Entries from './components/Entries'
import DateBar from './components/DateBar'
import SettingsPage from './components/SettingsPage'
import StatsPage from './components/StatsPage'
import NewMetricPage from './components/NewMetricPage'
import { ReactComponent as PlusCircleIcon } from './images/plus-circle.svg'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from 'react-router-dom'
import dayjs from 'dayjs'
import logo from './images/logo.png'

import './styles.css'

const App = () => {
  useEffect(() => {
    console.log('<App.js/> rendered')
  })

  return (
    <div className='app'>
      <AppProvider>
        <Router>
          {/* <TransitionGroup>
          <CSSTransition timeout={300} classNames="fade" key={location}> */}
          <header className='container'>
            <Link to='/'>
              <img alt='Metrics logo' className='logo' src={logo} />
            </Link>
          </header>
          <Switch>
            <Redirect exact from='/' to={`${dayjs().format('/YYYY/MM/DD')}`} />
            <Route exact path='/:y/:m/:d/'>
              <DateBar />
              <main>
                <Entries />
                <div className='container'>
                  <Link to='/metrics/new'>
                    <button className='center'>
                      <PlusCircleIcon className='icon' /> Add new metric
                    </button>
                  </Link>
                </div>
              </main>
            </Route>
            <Route exact path='/metrics/new' component={NewMetricPage} />
            <Route exact path='/settings' component={SettingsPage} />
            <Route exact path='/stats/:metricId' component={StatsPage} />
            <Route path='*'>
              <div>Not Found</div>
            </Route>
          </Switch>
          {/* </CSSTransition>
        </TransitionGroup> */}
        </Router>
      </AppProvider>
    </div>
  )
}

export default App

// TODO
// [x] store and retrive data in indexeddb
// [x] graph view for numeric input type
// [x] url routing
// [ ] view transitions
// [ ] graph for other input types
// [x] create custom metrics
// [ ] timerange selector for graphs
