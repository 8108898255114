import React, { useEffect, useState } from 'react'
import db from '../utils/db'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as CheckIcon } from '../images/check.svg'
import { ReactComponent as GraphIcon } from '../images/graph-filled.svg'
import { ReactComponent as TrashIcon } from '../images/trash-filled.svg'

const Entry = ({ metric }) => {
  const [value, setValue] = useState(false)
  const { y, m, d } = useParams()

  const date = `${y}-${m}-${d}`

  const handleChange = async (event) => {
    const newValue = !value

    setValue(newValue)

    const entryValue = newValue

    await db.entries.put({
      id: date + '-' + metric.id,
      date: date,
      metric: metric.id,
      value: entryValue
    })

    console.log('put ' + entryValue)
  }

  const handleClearButton = async (event) => {
    setValue('')

    await db.entries
      .where({
        date: date,
        metric: metric.id
      })
      .delete()
  }

  useEffect(() => {
    const fetchData = async () => {
      const entry = await db.entries
        .where({
          metric: metric.id,
          date: date
        })
        .first()

      if (entry !== undefined) {
        setValue(entry.value)
      } else {
        setValue(false)
      }
    }

    fetchData()

    console.log(`<Entry/> ${metric.name} rendered for ${date}`)
  }, [date, metric.name, metric.id])

  return (
    <div className='entry'>
      <p className='input-label'>{metric.name}</p>
      <div className='entry-input checkbox'>
        <label className={value ? 'checked' : 'unchecked'} htmlFor={metric.id}><CheckIcon className='checkbox-icon' /></label>
        <input id={metric.id} checked={value} onChange={handleChange} type='checkbox' />
      </div>
      <div className='button-group'>
        <Link to={`/stats/${metric.id}`}>
          <button>
            <GraphIcon className='icon' /> Graph
          </button>
        </Link>
        <button onClick={handleClearButton}>
          <TrashIcon className='icon' /> Clear
        </button>
      </div>
    </div>
  )
}

export default Entry
