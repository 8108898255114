import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

const fromString = (string) => dayjs(string, 'YYYY-MM-DD')
const toString = (date) => date.format('YYYY-MM-DD')
const toHumanString = (date) => {
  return fromString(date).format('MMMM Do, YYYY')
}
const toFormat = (date, format) => {
  return fromString(date).format(format)
}
const fromUrlPath = (urlPath) => dayjs(urlPath, '/YYYY/MM/DD')
const toUrlPath = (date) => date.format('/YYYY/MM/DD')
const fromIdToUrlPath = (id) => dayjs(id, 'YYYY-MM-DD').format('/YYYY/MM/DD')

export default {
  fromString,
  fromUrlPath,
  fromIdToUrlPath,
  toFormat,
  toHumanString,
  toString,
  toUrlPath
}
